<!-- Author:孔德昊 -->

<!-- importModule是父组件，select、editFiled、startimport作为子组件放在父组件内 -->
<!-- “编辑字段映射”页面 -->
<template>
  <div class="importmain">
    <div class="imain">
      <!-- 步骤条 -->
      <div class="header">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-selectgrey"></use>
        </svg>
        <span class="normal">{{
          $t("label.import.index.selectobjectandfiles")
        }}</span
        ><!-- 选择对象与文件-->
        <span>··············································</span>
        <svg class="icon" aria-hidden="true"><use href="#icon-edit"></use></svg>
        <span class="progress">{{ $t("label.import.index.editfield") }}</span
        ><!--  编辑字段映射 -->
        <span>··············································</span>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-importgrey"></use>
        </svg>
        <span class="normal">{{ $t("label.import.index.startimport") }}</span
        ><!-- 开始导入 -->
      </div>

      <!-- Tip提示 -->
      <div class="nav">
        <p style="white-space: nowrap; font-size: 14px; color: #080707">
          <span
            >{{ $t("label.import.index.obj") }}：{{
              $store.state.labelName
            }}</span
          >
          <!-- 对象-->
          <span style="margin-left: 40px"
            >{{ $t("label.import.index.operation") }}：{{
              $store.state.swicthtablebal
            }}</span
          >
          <!-- 操作：-->
          <span style="margin-left: 40px"
            >{{ $t("label.import.page2.filedname") }}：{{
              this.$store.state.filename
            }}</span
          ><!-- 文件名：-->
        </p>
        <p>
          {{ $t("label.import.index.notice")
          }}<!--注：-->
        </p>
        <p>
          {{ $t("label.import.step2.beforeexport") }}
          <!-- 1、导入之前，请先确保导入其相关的所有记录。例如：如果您当前导入的对象与联系人之间存在查找关系，请先导入这些联系人，然后再导入您的数据。-->
        </p>
        <p>
          {{ $t("label.import.step2.chooseidorother") }}
          <!--2、可以选择使用id或名称匹配记录与填写查找字段。若需获取对应记录id等信息，可创建报表，并将记录名称与记录id设置为组列，再将报表导出。-->
        </p>
      </div>
      <el-dialog
        :title="$t('label_popup_infoz')"
        :visible.sync="dialogVisible"
        width="30%"
        :show-close="false"
        :close-on-click-modal="false"
        ><!--提示-->
        <span>
          {{
            $t("label.import.page2.editforname", {
              necessaryName: necessaryName,
            })
          }}
          <!--请为字段 {{ necessaryName }} 编辑映射--></span
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >{{ $t("label.import.page2.confirm")
            }}<!--确 定--></el-button
          >
        </span>
      </el-dialog>
      <!-- 表格 -->
      <div class="biaoge">
        <!-- 表格上半部分 -->
        <div class="formup">
          {{
            $t("label.import.page2.notmatchfiled", {
              csvfiledlength: csvfiledlength,
              difflength: difflength,
            })
          }}
          <!-- 文件中共{{ csvfiledlength }}个字段，剩余{{csvfiledlength - selectlength}}个未映射 -->
        </div>

        <!-- 表格下半部分：表格主体 -->
        <div class="formdown">
          <el-table
            :row-style="{ height: 0 + 'px' }"
            :cell-style="{ padding: 0 + 'px' }"
            :data="tableData"
            height="500px"
            border
            style="width: 100%"
            v-loading="loading"
            element-loading-background="#fff"
          >
            <!-- 第一列 -->
            <el-table-column
              prop="name"
              :label="$t('label.import.page2.csvfiled')"
              :resizable="false"
            >
              <!--CSV字段-->
            </el-table-column>

            <!-- 第二列 -->
            <el-table-column
              prop="newselect"
              :label="$t('label.import.page2.CloudCCfiled')"
              :resizable="false"
              width="450"
            >
              <!--cloudcc字段-->
              <template slot-scope="scope">
                <div
                  v-for="(item, index) in scope.row.newselect"
                  :key="index"
                  style="margin-left: -10px"
                >
                  <el-cascader
                    placeholder=""
                    filterable
                    clearable
                    v-model="item.value"
                    :separator="separator"
                    :options="cloudccoption"
                    :disabled="item.disabled"
                    :props="{
                      value: 'fieldname',
                      label: 'label',
                      emitPath: false,
                    }"
                    @change="selectchange()"
                  >
                    <template slot-scope="{ data }">
                      <span v-if="data.fieldname === 'name'" style="color: red">
                        ★
                      </span>
                      <span>{{ data.label }}</span>
                    </template>
                  </el-cascader>

                  <!-- 加减号 -->
                  <span
                    class="delete_symbol"
                    @click="deleteOption(scope.row.newselect, scope, index)"
                    v-if="scope.row.newselect.length > 1"
                    >-</span
                  >
                  <span
                    class="add_symbol"
                    @click="addOption(scope.row.newselect, scope, index)"
                    v-show="scope.row.newselect.length < 5"
                    >+</span
                  >
                </div>
              </template>
            </el-table-column>

            <!-- 示例列 纯展示用-->
            <el-table-column
              prop="shili1"
              :label="$t('label.import.page2.example')"
              :resizable="false"
            >
              <template slot-scope="scope">
                <div>{{ example1[scope.$index] }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="shili2"
              :label="$t('label.import.page2.example')"
              :resizable="false"
            >
              <template slot-scope="scope">
                <div>{{ example2[scope.$index] }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="shili3"
              :label="$t('label.import.page2.example')"
              :resizable="false"
            >
              <template slot-scope="scope">
                <div>{{ example3[scope.$index] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="Buttondiv">
        <el-button class="lastButton" @click="lastButton()">{{
          $t("label.import.page1.laststep")
        }}</el-button>
        <!--上一步-->
        <el-button class="nextButton but0" @click="nextone()" v-if="nonameshow"
          >{{ $t("label.import.page1.nextstep")
          }}<!--下一步--></el-button
        >
        <el-button class="nextButton but1" @click="nexttwo()" v-if="hasnameshow"
          >{{ $t("label.import.page1.nextstep")
          }}<!--下一步--></el-button
        >
        <el-button
          class="nextButton but2"
          @click="repeatselect()"
          v-if="revalueshow"
          >{{ $t("label.import.page1.nextstep")
          }}<!--下一步--></el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { importFieldList } from "./api";

export default {
  data() {
    return {
      necessaryName: "",
      revalueshow: "",
      filelistobjid: "",
      nonameshow: true,
      hasnameshow: "",
      separator: "-",
      cloudccoption: [],
      csvfiledlength: "",
      tableData: [],
      matcharr: [],
      matcharrlength: [],
      selectlength: Number,
      dialogVisible: false,
      loading: false,
      countnum: 0,
    };
  },
  created() {
    this.importFieldList();
    this.loading = true;
  },

  mounted() {
    const allfield = this.$store.state.curdata;
    this.csvfiled = allfield[0]; //第一列数据
    this.example1 = allfield[1]; //第二列数据
    this.example2 = allfield[2];
    if ((this.example2 = undefined)) {
      this.example2 === "";
    }
    this.example3 = allfield[3];
    this.csvfiledlength = allfield[0].length;
    this.$store.commit("getcsvfiledlength", this.csvfiledlength);
  },

  watch: {
    tableData: {
      handler() {
        this.selectchange(); //todo：执行了两次
      },
      immediate: true,
    },
  },

  methods: {
    selectchange() {
      // 获取到父级选中节点
      let tableData = JSON.parse(JSON.stringify(this.tableData));
      // 把所有已选中的字段放入selectOfValue
      let selectOfValue = [];
      this.countnum = 0;
      tableData.forEach((itemx) => {
        let newselectarr = itemx.newselect;
        if (
          Number(
            newselectarr.some((item) => Object.values(item).some(Boolean))
          ) === 1
        ) {
          this.countnum += 1;
          this.$store.commit("getcountnum", this.countnum);
        }
        itemx.newselect.forEach((itemy) => {
          let temparr = (itemy.value || "").split(",");
          if (temparr.length > 1) {
            itemy.value && selectOfValue.push(temparr[0] + "," + temparr[1]);
          } else {
            itemy.value && selectOfValue.push(temparr[0]);
          }
        });
      });
      this.selectlength = selectOfValue.length;
      this.$store.commit("getselectlength", this.selectlength);

      // 解析文件后，关闭加载动画
      this.loading = false; //选中之后选项禁用
      this.cloudccoption.forEach((item) => {
        this.$set(item, "disabled", false);
        selectOfValue.forEach((ite) => {
          if (item.fieldname == ite) {
            this.$set(item, "disabled", true);
          }
        });
      });

      // 遍历数组后把索引里带逗号的取逗号前的值，重新赋值此数组
      selectOfValue = selectOfValue.map((item) => item.split(",")[0]);
      // 没有重复的元素
      if (selectOfValue.length === new Set(selectOfValue).size) {
        // 判断接口importFieldList 返回的导入字段的fieldname属性 是否含有 'name'
        let isFieldListName = this.cloudccoption.some(
          (value) => value.fieldname === "name"
        );
        // 含有 "name"  且如果选中了必选字段“name” 可进行下一步 开始导入
        if (isFieldListName === true) {
          if (selectOfValue.indexOf("name") !== -1) {
            //如果选中了必选字段“name”
            this.nonameshow = false;
            this.hasnameshow = true;
            this.revalueshow = false;
          } else {
            this.nonameshow = true;
            this.hasnameshow = false;
            this.revalueshow = false;
          }
        } else {
          // 没有 "name"  可进行下一步 开始导入
          this.nonameshow = false;
          this.hasnameshow = true;
          this.revalueshow = false;
        }
      } else {
        // 有重复的元素
        this.nonameshow = false;
        this.hasnameshow = false;
        this.revalueshow = true;
        this.$message({
          message: "不能重复选择", //"一个cloudcc字段只能和一个文件中字段对应",
          type: "warning",
        });
      }

      // 传给后台的数据先用commitdata接收,放在vuex
      const staydata = this.tableData
        .map((item) => {
          const itemx = item.newselect.map((selectitem) => {
            return {
              file_apiname: item.name,
              object_apiname: selectitem.value,
              objId: this.$store.state.filelistobjid,
            };
          });
          return itemx;
        })
        .flat();

      const commitdata = staydata.flat();
      this.$store.commit("getcommitdata", commitdata);
    },
    repeatselect() {
      this.$message({
        message: this.$i18n.t("label.import.page2.oneonone"), //"一个cloudcc字段只能和一个文件中字段对应",
        type: "warning",
      });
    },
    nextone() {
      this.dialogVisible = true;
    },
    nexttwo() {
      this.$router.push(`/importModule/step3`);
    },
    lastButton() {
      this.$router.push(`/importModule/step1`);
    },
    matchFields() {
      

      let res = this.csvfiled.map((item, index) => {
        let matchOption = "";
        let option = this.cloudccoption.find((option) => option.label === item);
        if (option) {
          // 判断字段类型如果是"Y"或者"M"，就自动匹配上查找字段的name属性，然后赋值给matchOption
          matchOption =
            option.fieldtype === "Y" || option.fieldtype === "M"
              ? `${option.fieldname},name`
              : option.fieldname;
          this.matcharr.push(matchOption);
          this.matcharrlength = this.matcharr.length;
          this.$store.commit("getmatcharrlength", this.matcharrlength);
        }
        // eslint-disable-next-line no-constant-condition
        if ((this.example1 = false)) {
        // eslint-disable-next-line no-constant-condition
        } else if ((this.example2 = false)) {
        // eslint-disable-next-line no-constant-condition
        } else if ((this.example3 = false)) {
          setTimeout(()=>1+1)
        }


        return {
          name: this.csvfiled[index],
          newselect: [{ value: matchOption }],
          shili1: this.example1[index],
          shili2: this.example2[index],
          shili3: this.example3[index],
        };
      });
      this.tableData = res;
    },
    // 获取导入字段
    async importFieldList() {
      let params = {
        id: this.$store.state.id,
      };
      let res = await importFieldList(params);
      if (res.result) {
        this.cloudccoption = res.data.fieldList;
        this.filelistobjid = this.cloudccoption[0].objid; //获取字段的objId
        this.$store.commit("getfilelistobjid", this.filelistobjid);
        this.cloudccoption.forEach((option) => {
          if (option.fieldname === "name") {
            this.necessaryName = option.label;
          }
          //当字段类型是Y为查找字段，M类型是主祥字段，写死children赋值给到对应的M/Y字段
          if (option.fieldtype === "Y" || option.fieldtype === "M") {
            let children = [
              {
                fieldname: `${option.fieldname},id`,
                label: "id",
              },
              {
                fieldname: `${option.fieldname},name`,
                label: this.$i18n.t("label.chatter.name"), //名称
              },
            ];
            option.children = children;
          }
        });
        this.matchFields();
        this.$store.commit("getcloudccoption", this.cloudccoption);
      }
    },

    // 增加一行
    addOption(data, scope) {
      this.tableData[scope.$index].newselect = this.tableData[
        scope.$index
      ].newselect.concat({ value: "" });
    },
    // 删除一行
    deleteOption(newselect, scope, index) {
      this.tableData[scope.$index].newselect.splice(index, 1);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/importModule/step3") {
        vm.$route.meta.keepalive = true;
      } else {
        vm.$route.meta.keepalive = false;
      }
    });
  },
  computed: {
    difflength: function () {
      return this.csvfiledlength - this.countnum;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .home_content .el-main {
  padding: 0;
  overflow: auto;
  background-color: #fff;
}
::v-deep .el-input__inner {
  border: 0;
  border-radius: 0;
}

::v-deep .el-dialog__header {
  border: none;
}
::v-deep .el-dialog__footer {
  border: none;
}
.requiredname {
  color: red;
}
.requiredname：before {
  content: "*";
}
::v-deep .el-table--enable-row-hover {
  height: 458px !important;
}
.Buttondiv {
  margin-top: 30px;
  float: right;
  outline: none;
}
.nextButton {
  background-color: #006dcc;
  color: #fff;
}
.lastButton {
  color: #666;
  border: 1px solid #e2e2e2;
}
.lastButton:hover {
  background-color: #fff;
  color: #666;
  border: 1px solid #e2e2e2;
}
.add_symbol {
  font-size: 24px;
  cursor: pointer;
}
.delete_symbol {
  font-size: 24px;
  margin: 0 20px;
  cursor: pointer;
}

::v-deep .el-input--suffix {
  width: 370px !important;
}
::v-deep .el-cascader__dropdown {
  box-shadow: 5;
  border: 0 !important;
}
.el-input__icon {
  margin-top: -2px;
}
.spanword {
  font-size: 12px;
}
::v-deep .is-scrolling-none {
  height: 360px !important;
}
.formup {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 44px;
  font-size: 14px;
  border-bottom: 1px solid #d8d8d8;
}
.formdown {
  text-align: center;
}
::v-deep .el-table--enable-row-transition {
  // overflow: scroll;
  height: 410px !important;
  width: 96% !important;
  margin: 20px auto !important;
}

.el-select-dropdown__item.selected {
  background-color: #dceeff;
}
.el-select-dropdown__item:hover {
  background-color: #dceeff;
}
.el-select-dropdown__empty {
  width: 180px !important;
}
.importmain {
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  margin: 10px;
}
.imain {
  width: 100%;
  height: 100%;
  padding: 0 140px 0 140px;
  .header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 36px;
      height: 36px;
    }
  }
  .nav {
    width: 100%;
    // min-width: 1500px;
    height: 136px;
    margin-top: -22px;
    p {
      font-size: 14px;
      color: #666666;
    }
  }

  .biaoge {
    width: 100%;
    height: 510px;
    border: 1px solid #d8d8d8;
  }
}
.normal {
  font-size: 16px;
  color: #191717;
  margin: 0 5px 0 5px;
}
.progress {
  font-size: 16px;
  color: #006dcc;
  margin: 0 5px 0 5px;
}

.header > img {
  width: 36px;
  height: 36px;
  margin-left: 6px;
}
.des {
  font-size: 14px;
  color: #080707;
}
td {
  border: 1px solid #d8d8d8;
  padding: 0px;
}
::v-deep .el-select__caret {
  color: black !important;
}

.button {
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #888;
}
.el-select {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: -1px;
}
.namered {
  color: red;
}
el-dialog {
  span:nth-child(1) {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular;
    color: #666666;
  }
}
</style>
